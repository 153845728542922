const btn = document.getElementById("submitBtn");
const arrLeft = document.querySelector(".contact-form__arrow-left");
const menuSwitcher = document.querySelector(".navbar-toggler");
const menu = document.querySelector(".mobile-menu");
let links = document.querySelectorAll(".menu__link");

// menuSwitcher.addEventListener("click", () => {
//   menu.classList.toggle("active-menu");
// });

links = Array.from(links);

links.forEach((link) => {
    link.addEventListener("click", () => {
        if (menu.classList.contains("active-menu")) {
            menu.classList.remove("active-menu");
            $("#custom-nav-toggle").removeClass("toggler-active");
        }
    });
});

$(".navbar-brand").on("click", function () {
    $("#custom-nav-toggle").removeClass("toggler-active");
    $(".mobile-menu").removeClass("active-menu");
});

$(".toggle-trigger").on("click", function () {
    $("#custom-nav-toggle").toggleClass("toggler-active");
    $(".mobile-menu").toggleClass("active-menu");
});

$(window).scroll(function () {
    $("nav").toggleClass("resized", $(this).scrollTop() > $("nav").height());
});

const teamBtn = document.getElementById("open-modal");
const modal = document.querySelector(".modal");
const closeBtn = document.querySelector(".btn-close");
teamBtn.addEventListener("click", () => {
    modal.style.display = "block";
    document.querySelector("body").style.overflowY = "hidden";
});

closeBtn.addEventListener("click", () => {
    modal.style.display = "none";
    document.querySelector("body").style.overflowY = "auto";
});

$(window).on("load scroll", function () {
    var parallaxElement = $(".bellwether, .bellwether-mobile"),
        parallaxQuantity = parallaxElement.length;
    window.requestAnimationFrame(function () {
        for (var i = 0; i < parallaxQuantity; i++) {
            var currentElement = parallaxElement.eq(i),
                windowTop = $(window).scrollTop(),
                elementTop = currentElement.offset().top,
                elementHeight = currentElement.height(),
                viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5,
                scrolled = windowTop - elementTop + viewPortHeight;
            currentElement.css({
                transform: "translate3d(0," + scrolled * -0.35 + "px, 0)",
            });
        }
    });
});

$(function () {
    var desktop = window.innerWidth > 786;
    var slider = $(".extraslider");
    var position = $(".extraslider").offset().top;
    var lastScrollTop = 0;
    var count = parseInt($(".slider-counter").text());
    var counter = $(".slider-counter");
    var sliderItems = $("#scene").children();
    var nextItemBtn = $("#next");
    var containerWidth = $("#point").width();
    var bodyWidth = $("body").width();
    var horizontal = $(".horizontal"),
        navSlider = $(".extraslider__nav");
    // use to: $(item[2]).offset().left

    console.log(navSlider.off)
    console.log(count);


    var elementAndMarginWidth = $(sliderItems[0]).width() + parseInt($(sliderItems[0]).css("margin-right"));
    console.log(elementAndMarginWidth)
    var dynamicalHeight = (sliderItems.length * 100) - 90;
    // var dynamicalHeight = elementAndMarginWidth * (sliderItems.length - 1) * .75;
    // horizontal.css({width: `calc(${dynamicalWidth}vw - 600px`});

    var n = sliderItems.length; // ilość elementów w sliderze
    var x = $(".extraslider__container").outerWidth(); // szerokość elementu slidera
    var x2 = x;
    if (desktop) {
        x = x / window.innerWidth * 100;
    }
    if (!desktop) {
        x = x * 1.01;
    }
    // x = x + window.innerHeight;
    if (desktop) {
        var c = 25;
    }
    if (!desktop) {
        var c = window.innerHeight / 3;
    }
    // var c = window.innerHeight * .75;
    // var y = parseInt($(sliderItems[0]).css("margin-right")); // wielkość odstępu pomiędzy sliderami

    var distanceToScroll = (x + c + ((n - 2) * (x / 2)));  // obliczanie długości elementów slidera - bez ostatniego slidera, bo musi on zostać na ekranie
    console.log(distanceToScroll);
    //slider.css({height: `calc(${(n - 1) * x}px - 33vh)`}); // ustawienie dystansu do skolowania
    slider.css({height: `${distanceToScroll}vw`}); // ustawienie dystansu do skolowania
    horizontal.css({width: `${n * x}vw`}); // ustawienie długości slidera do skolowania
    var maxIteration = sliderItems.length;
    nextItemBtn.on("click", function () {
        // sliderItems.length--;
        $this = $(this);
        if (counter.text() <= maxIteration) {
            $("html, body").animate(
                {
                    scrollTop: $this.offset().top + x,
                },
                100
            );
        } else {
            return false;
        }
    });
    var cur = x;

    var contSlider = $("#scene");
    var _counter = 1,
        contentS = $(contSlider).offset().top,
        scrollIterator = contentS,
        wS = $(this).scrollTop();


    var start = x2 + contentS;
    var distance = start - x2;
    var items = $(".extraslider__container");


    $(window).on("scroll", function () {
        var scrollTop = $(window).scrollTop(),
            wS = $(this).scrollTop();
        if (scrollTop >= position) {
            var translate = ((scrollTop - position) / $(window).height()) * 100;
            $("#scene").css({transform: "translateX(-" + translate + "vw)"});
            console.log(`scrollTop: ${scrollTop} || distance: ${distance}`)
            if (scrollTop > distance) {
                // if (distance < (x2*(n-1) - start)) {
                //     _counter++;
                //     counter.text(_counter);
                // }
                if (_counter <= maxIteration) {
                    if (wS > lastScrollTop) {
                        _counter++;
                        // if (desktop) {
                            distance += x2 / 2;
                        // }
                        // if (!desktop) {
                        //     distance += x2 / 2;
                        // }
                    }

                } else {
                    nextItemBtn.attr("disabled", "disabled");
                    nextItemBtn.css("opacity", ".5");
                }
                console.log(_counter);

            }

            if (translate >= ((n - 1) * x) && bodyWidth > 770) {
                $("#scene").css({transform: `translateX(-${(n - 1) * x}vw)`});
            } else if (translate >= 190 && bodyWidth <= 770) {
                // $("#scene").css({transform: `translateX(-${(n - 1) * x}vw)`});
                // $("#scene").css({transform: "translateX(-200vw)"});
            }

            if (parseInt($(".extraslider").offset().top) - 500 >= scrollTop) {
                $("#scene").css({transform: "translateX(0vw)"});
            }
            if (scrollTop < lastScrollTop) {
                if (translate < 15.5) {
                    $("#scene").css({transform: "translateX(0vw)"});
                }
            }
            lastScrollTop = scrollTop;
        }

        items.each(function (index) {
            var centerScreenX = window.innerWidth * .6;
            var rangeA = $(items[index]).offset().left;
            if(index == items.length - 1 && rangeA < centerScreenX){
                counter.text(items.length);
                nextItemBtn.attr("disabled", "disabled");
                nextItemBtn.css("opacity", ".5");
            }else if (index < items.length - 1) {
                var rangeB = $(items[index + 1]).offset().left;

                if (rangeA < centerScreenX && rangeB > centerScreenX) {
                    counter.text(index + 1);
                }
                nextItemBtn.removeAttr("disabled");
                nextItemBtn.css("opacity", "1");
            }
        });
    });
});

$(".footer__social-link").on("mouseenter", function () {
    var that = $(this);
    $(".footer__arrow-right").css({top: `${that.position().top}px`});
})

const marquee = function () {
    // not used anymore
    var marqueeAnimationId,
        copyAnimationElement,
        startPosAnimationElement,
        posAnimationElement,
        animationElement;

    function setMarqueeElement() {
        animationElement = document.querySelector(".marquee-text-blwr");
        parentElement = document.querySelector(".marquee-container-blwr");
        copyAnimationElement = animationElement;
        body = document.querySelector("body");
        parentAnimationElementWidth = body.offsetWidth;
        startPosAnimationElement = -body.offsetWidth;
        posAnimationElement = startPosAnimationElement;
        animationElement.style.right = posAnimationElement + "px";
        var duplicatedText = animationElement.textContent.trim();
        var spanText = document.createElement("span");
        spanText.classList.add("duplicated-title");
        spanText.innerText = duplicatedText;
        for (var i = 0; i < 5; i++) {
            var spanText = document.createElement("span");
            spanText.classList.add("duplicated-title");
            spanText.innerText = duplicatedText;
            animationElement.appendChild(spanText);
        }
    }

    function moveMarqueeElement() {
        if (body.offsetWidth < 600) {
            posAnimationElement += 1.5;
        } else {
            posAnimationElement += 3;
        }
        animationElement.style.right = posAnimationElement + "px";
        if (posAnimationElement > animationElement.offsetWidth)
            posAnimationElement = startPosAnimationElement;
        marqueeAnimationId = requestAnimationFrame(moveMarqueeElement);
    }

    setMarqueeElement();
    moveMarqueeElement();
    window.addEventListener("resize", refreshAnimation, false);

    var resizeTimeout;

    function refreshAnimation() {
        if (!resizeTimeout) {
            resizeTimeout = setTimeout(function () {
                resizeTimeout = null;
                window.cancelAnimationFrame(marqueeAnimationId);
                setMarqueeElement();
                moveMarqueeElement();
            }, 100);
        }
    }
};


const team = document.querySelector(".team-grid");
team.addEventListener("click", (e) => {
    if (e.target.tagName != "IMG") return;
    modal.style.display = "block";
    document.querySelector("body").style.overflowY = "hidden";
})